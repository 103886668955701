/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import { isEqual } from "lodash";
import { forkJoin } from 'rxjs';

import Select from "@material-ui/core/Select";
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Own
import { store } from "store/store";
import { onResponseError } from "services/API/API.interceptor.js"
import API, { APIN } from "services/API/API";
import { FieldMetaGroup, FieldGroup } from 'components/Common/Interfaces/Entity.interface';
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { APIPrivileges } from "services/Interface/Interface";
import { getPermitDocumentsRoute } from "components/Schedulers/Services/visitService";
import { getPTWFormFieldsConfig, useGetPTWFormLayout } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";
import HotWorkIsolations from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/HotWorkIsolations";
import PTWTrainingCourses from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/PTWTrainingCourses";
import Roles from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/ConfinedSpaceRolesAndResponsibilities";
import { BasePermitToWork, ConfinedSpacesPTW, HotWorksPTW, LimitationOfAccessPTW, WorkPermitType, WorkingAtHeightPTW, workPermitTypesArray } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import CommonAPIServices from "components/ContractInFocus/Services/commonAPI.services";
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions, GeneralButtonDefinition } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { getDataAndMeta } from "services/API/API.helper"
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { profileSelector } from "components/Profile/Selectors/Profile.selector";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { WorkPermitDocViewer } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitDocVIewer";
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { Results } from 'components/Common/Interfaces/Entity.interface';
import { PtwType } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";
import PermitSupportingDocs from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/PermitSupportingDoc";
import { RequirementsHelpText } from 'components/Common/Components/AutoInput/AutoInput';


// Styles
import "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/workPermitForms.scss";
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';

export const generatePermitLabel = 'Generate Permit';
export const PTWSaveLabel = 'Save';

interface PTWAfterFormComponentsProps {
    ptw: BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW,
    permitType: WorkPermitType,
    setTopManMissing: React.Dispatch<React.SetStateAction<boolean>>,
    setEntrantMissing: React.Dispatch<React.SetStateAction<boolean>>,
    setRescuePersonMissing: React.Dispatch<React.SetStateAction<boolean>>,
}

const PTWAfterFormComponents = (
    { ptw,
        permitType,
        setTopManMissing,
        setEntrantMissing,
        setRescuePersonMissing }: PTWAfterFormComponentsProps
) => {
    if (permitType === "Confined Space") {
        return <>
            <div className='ptwInfoListItem'>
                <PTWTrainingCourses
                    ptw={ptw as BasePermitToWork}
                    title="Training Courses"
                />
            </div>
            <div className='ptwInfoListItem'>
                <Roles
                    ptw={ptw as BasePermitToWork}
                    title="Roles and Responsibilities"
                    setTopManMissing={setTopManMissing}
                    setEntrantMissing={setEntrantMissing}
                    setRescuePersonMissing={setRescuePersonMissing}
                />
            </div>
        </>
    }
    if (permitType === "Hot Works") {
        return <>
            <div className='ptwInfoListItem'>
                <HotWorkIsolations
                    ptw={ptw as HotWorksPTW}
                    title="Isolation Systems"
                />
            </div>
        </>
    }
    return <></>
};

type CombinedPermitTypes = BasePermitToWork | HotWorksPTW | ConfinedSpacesPTW | WorkingAtHeightPTW | LimitationOfAccessPTW;

interface GetEndpointForPermitTypeProps {
    visitId?: string,
    selectedPermitType?: string,
    basePermitId?: string,
    subPermitId?: string,
}

const getEndpointForPermitType = ({ selectedPermitType, visitId, basePermitId, subPermitId }: GetEndpointForPermitTypeProps) => {
    switch (selectedPermitType) {
        case "Hot Works": {
            return `ptws/${basePermitId}/hot-work-ptws/${subPermitId ? subPermitId + '/' : ''}`;
        };
        case "Confined Space": {
            return `ptws/${basePermitId}/confined-space-ptws/${subPermitId ? subPermitId + '/' : ''}`;
        };
        case "Limitation of Access": {
            return `ptws/${basePermitId}/limitation-of-access-ptws/${subPermitId ? subPermitId + '/' : ''}`;
        };
        case "Working At Height": {
            return `ptws/${basePermitId}/working-at-height-ptws/${subPermitId ? subPermitId + '/' : ''}`;
        };
        default: {
            return `visits/${visitId}/ptws/${basePermitId ? basePermitId + '/' : ''}`;
        }
    }
}
// const getEndpointForPermitType = (visitId: string, selectedPermitType?: string, permitId?: string) => {
//     const baseEndpoint = `visits/${visitId}/`;
//     const id = permitId ? `${permitId}/` : '';
//     switch (selectedPermitType) {
//         case "Hot Works": {
//             return `${baseEndpoint}hot-work-ptws/${id}`;
//         };
//         case "Confined Space": {
//             return `${baseEndpoint}confined-space-ptws/${id}`;
//         };
//         case "Limitation of Access": {
//             return `${baseEndpoint}limitation-of-access-ptws/${id}`;
//         };
//         case "Working At Height": {
//             return `${baseEndpoint}working-at-height-ptws/${id}`;
//         };
//         default: {
//             return `${baseEndpoint}ptws/${id}`;
//         }
//     }
// }

interface getPermitTypeOptionsProps {
    setPermitOptionResponses: any,
    visitId: string,
    permitId?: string,
    existingPermitType: string
}
const getPermitTypeOptions = ({
    setPermitOptionResponses,
    visitId,
    permitId,
    existingPermitType
}: getPermitTypeOptionsProps) => {
    // if we have an existing permitType, we can simply set the response
    let endpoint;
    if (existingPermitType) {
        endpoint = getEndpointForPermitType({
            selectedPermitType: existingPermitType,
            basePermitId: permitId,
            // subPermitId added to endpoint for this case, but is passed in to call to getPreFlightInfo function
            // rather than being included here
        });
        CommonAPIServices.getPreFlightInfo(endpoint, permitId).then(response => {
            setPermitOptionResponses({
                [existingPermitType]: response
            });
        }).catch(
            (error) => { console.log(error) }
        )
    } else {
        // now we are going to want to iterate the permit types to provide the responses for all permitTypes (as the permitType can still be changed)
        forkJoin({
            "Confined Space": CommonAPIServices.getPreFlightInfo(getEndpointForPermitType({ selectedPermitType: "Confined Space", basePermitId: permitId })),
            "Hot Works": CommonAPIServices.getPreFlightInfo(getEndpointForPermitType({ selectedPermitType: "Hot Works", basePermitId: permitId })),
            "Working At Height": CommonAPIServices.getPreFlightInfo(getEndpointForPermitType({ selectedPermitType: "Working At Height", basePermitId: permitId })),
            "Limitation of Access": CommonAPIServices.getPreFlightInfo(getEndpointForPermitType({ selectedPermitType: "Limitation of Access", basePermitId: permitId })),
            "Base": CommonAPIServices.getPreFlightInfo(getEndpointForPermitType({ visitId, basePermitId: permitId }))

        }).subscribe(response => setPermitOptionResponses(response))
    }
}

interface WorkPermitDetailProps {
    data: BasePermitToWork;
    visitId: string;
    initialPermissions?: APIPrivileges;
    dataSource: DataSource;
    docBaseRoute: string;
    setData: (value: React.SetStateAction<BasePermitToWork>) => void;
    preFlightDocInfo: PreFlightListInfo | undefined;
    currentListDataRef: React.MutableRefObject<Results<PtwType> | undefined>;
}

function AppriseWorkPermitDetailView({
    data,
    visitId,
    initialPermissions,
    dataSource,
    docBaseRoute,
    setData,
    preFlightDocInfo,
    currentListDataRef
}: WorkPermitDetailProps) {

    const sharedSpaceRef = useRef<any>({});
    const sharedSpace = sharedSpaceRef.current;
    const baseEndpoint = `visits/${visitId}/`
    const detailEndpoint = useRef<string>(`${baseEndpoint}ptws/`);
    const pdfControllerEndpoint = `${baseEndpoint}ptw-pdf-controller/`;
    const [canRemovePDF, setCanRemovePDF] = useState(false);
    const [canGeneratePDF, setCanGeneratePDF] = useState(false);
    const [permitOptionResponses, setPermitOptionResponses] = useState<any>();
    const requirementsHelpText = useRef<RequirementsHelpText>({
        requiredSaveHelpText: "Required",
        requiredSubmitHelpText: "Required before permit generation",
        negativeRequiredSaveHelpText: "Required to be negative",
        negativeRequiredSubmitHelpText: "Required to be negative before permit generation",
        positiveRequiredSaveHelpText: "Required to be positive",
        positiveRequiredSubmitHelpText: "Required to be positive before permit generation",
    });

    const canWrite = useRef<boolean>();
    const profileState = useSelector(profileSelector);

    const [thisRecord, setThisRecord] = useState<CombinedPermitTypes>(sharedSpace.data || data);
    // thisRecord is only set on successful response (after a permit is created or updated and is initialised to the data passed into this component)
    // i.e. it represents an up to date version of the saved permit.
    const permissions = useRef<APIPrivileges | undefined>(initialPermissions);
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});

    const [methodStatementMissing, setMethodStatementMissing] = useState<boolean>(data.method_statement_missing);
    const [riskAssessmentMissing, setRiskAssessmentMissing] = useState<boolean>(data.risk_assessment_missing);
    const [rescuePlanMissing, setRescuePlanMissing] = useState<boolean>(data.rescue_plan_missing);

    const [topManMissing, setTopManMissing] = useState(false);
    const [entrantMissing, setEntrantMissing] = useState(false);
    const [rescuePersonMissing, setRescuePersonMissing] = useState(false);

    const removePDFEndpoint = `${pdfControllerEndpoint}${thisRecord.id}/remove_permit_pdf/`;
    const generatePDFEndpoint = `${pdfControllerEndpoint}${thisRecord.id}/retry_permit_pdf_generation/`;

    const [metaData, setMetaData] = useState<FieldMetaGroup>();
    const formValuesRef = useRef<any>(data || {});

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed
    const [assessGeneralValidity, setAssessGeneralValidity] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed

    const [permitType, setPermitType] = useState<WorkPermitType>(thisRecord.ptw_type);

    const [supportDocsResponse, setSupportDocsResponse] = useState<any>();
    //const [supportDocs, setSupportDocs] = useState<any>();

    const generatePermitPDF = useCallback(() => {
        API.post(generatePDFEndpoint).then((response) => {
            processSuccessfulResponse(response, undefined, true);
        })
    }, []);

    const RetryGeneratePermitPDFButton = () => (
        <Button
            onClick={() => {
                generatePermitPDF();
            }}
        >
            Re-try Generating Permit PDF
        </Button>
    )

    useEffect(() => {
        // this should only trigger if the file has changed...
        if (thisRecord?.agreement?.file) {
            forceUpdate();
        }
    }, [thisRecord?.agreement?.file])

    const removePermitPDF = useCallback(() => {
        API.post(removePDFEndpoint).then((response) => {
            processSuccessfulResponse(response, undefined, true);
        })
    }, []);

    const RemovePermitPDFButton = () => (
        <Button
            onClick={() => {
                removePermitPDF();
            }}
        >
            Remove Permit PDF
        </Button>
    )

    const updateCanRemovePDF = useCallback((): void => {
        APIN.get(removePDFEndpoint).then((response) => {
            if (response.data) {
                setCanRemovePDF(true);
            }
        }).catch(error => {
            if (error.response?.status == 403) {
                setCanRemovePDF(false);
            } else {
                return onResponseError(error)
            }
        })
    }, [removePDFEndpoint]);

    const updateCanGeneratePDF = useCallback((): void => {
        APIN.get(generatePDFEndpoint).then((response) => {
            if (response.data) {
                setCanGeneratePDF(true);
            }
        }).catch(error => {
            if (error.response?.status == 403) {
                setCanGeneratePDF(false);
            } else {
                return onResponseError(error)
            }
        })
    }, [generatePDFEndpoint]);

    useEffect(() => {
        updateCanGeneratePDF();
        updateCanRemovePDF();
    }, [updateCanGeneratePDF, updateCanRemovePDF])

    const getFormFieldsConfig = useCallback((canWrite: boolean, thisPermitType, printable): FieldsFormConfig => {
        const thisConfig = getPTWFormFieldsConfig(
            { data, canWrite, permitType: thisPermitType, currentUser: profileState, formValuesRef: formValuesRef }
        );

        Object.keys(thisConfig).map(
            (x: any) => {
                thisConfig[x].printable = printable
            }
        )

        return thisConfig;
    }, [thisRecord, profileState]);

    const formFieldsConfig = useRef<FieldsFormConfig>();
    const printableFormFieldsConfig = useRef<FieldsFormConfig>();

    const generalPTWValidator: () => FormErrorsType = useCallback(() => {
        // these are 'general' validations, so the keys don't have to map to fields
        let errors: FormErrorsType = {
            'missing_method_statement': { 'submit': methodStatementMissing ? "Method Statement missing" : undefined },
            'missing_risk_assessment': { 'submit': riskAssessmentMissing ? "Risk Assessment missing" : undefined }
        }
        if (permitType === "Confined Space") {
            errors = {
                ...errors,
                'missing_rescue_plan': { 'submit': rescuePlanMissing ? "Rescue Plan missing" : undefined },
                'missing_top_man': { 'submit': topManMissing ? "'Top man' role unfilled" : undefined },
                'missing_entrant': { 'submit': entrantMissing ? "Entrant role unfilled" : undefined },
                'missing_rescue_person': { 'submit': rescuePersonMissing ? "Rescue role unfilled" : undefined },
            }
        }
        return errors
        //}, [assessGeneralValidity, topManMissing, rescuePlanMissing, entrantMissing, rescuePersonMissing]);
    }, [assessGeneralValidity]);


    useEffect(() => {
        // we use a ref in combination with a useReducer rather to ensure that error evaluation will be permitted
        // and then triggering the change in the generalPTWValidator that will lead to the errors being reassessed
        // currently in runAllValidators. 
        // We use a ref rather than a boolean state for allowErrorReEvaluation, to avoid having to pass a setState down
        // to the GeneralActionForm as well 
        // and to avoid more rerenders when the state switches to false after the relevant error assessment block is entered
        setAssessGeneralValidity();
    }, [riskAssessmentMissing, methodStatementMissing, rescuePlanMissing, supportDocsResponse, topManMissing, rescuePersonMissing, entrantMissing]);

    const getRenderPermitTypePicker = useCallback((thisPermitType: string) => () => {
        if (thisRecord.ptw_type || !permitOptionResponses || !permitOptionResponses['Base'].canUpdate) {
            return <></>;
        } else {
            const now = moment();
            const generalPermitTrainingExpires = profileState?.data.permit_training_valid_to;
            const confinedSpacesTrainingValidTo = profileState?.data.confined_spaces_training_valid_to;
            const generalPermitTrainingExpired = generalPermitTrainingExpires ? now > moment(generalPermitTrainingExpires) : undefined;
            const confinedSpacesTrainingExpired = confinedSpacesTrainingValidTo ? now > moment(confinedSpacesTrainingValidTo) : undefined;
            return < FormControl className='permitTypePickerControl' >
                <InputLabel shrink htmlFor="permitTypeSelector-native-label">
                    Permit Type:
                </InputLabel>
                <Select
                    defaultValue={thisPermitType || ''}
                    onChange={(event: any) => {
                        setPermitType(event.target.value);
                    }}
                    id="permitTypeSelector"
                >
                    {workPermitTypesArray.map(wpt => {
                        // before we could say that if you're here you must have a permit editing connection to the contract
                        let msg = '';
                        if (wpt === "Confined Space") {
                            if (confinedSpacesTrainingExpired) {
                                msg = ' (Your confined spaces training has expired)'
                            } else if (confinedSpacesTrainingExpired === undefined) {
                                msg = ' (You have no registered confined space training)'
                            }
                        } else {
                            if (generalPermitTrainingExpired) {
                                msg = ' (Your permit training has expired)'
                            } else if (generalPermitTrainingExpired === undefined) {
                                msg = ' (You have no registered permit training)'
                            }
                        }
                        const thisResponse = permitOptionResponses[wpt];
                        const canCreate = thisResponse.canCreate;
                        return <MenuItem key={wpt} disabled={!!msg.length || !canCreate} value={wpt} className={wpt.replaceAll(" ", '')}>
                            {wpt}{msg}
                        </MenuItem>
                    }
                    )}
                </Select>
            </FormControl >
        }

    }, [thisRecord, profileState, permitOptionResponses]);

    const getRenderPermitRef = useCallback(() => () => {
        return <p className='permit-ref'>Permit Ref: {thisRecord.reference}</p>;
    }, [thisRecord]);


    useEffect(() => {
        const documentsPath = getPermitDocumentsRoute(data.id);
        API.get(documentsPath).then(response => {
            setSupportDocsResponse(response);
            //const thisDocsResponse = getDataAndMeta(response);
            //setSupportDocs(thisDocsResponse.data);
        })
    }, [data, mustRefresh]);

    const RenderPermitSupportingDocsPicker = useCallback(() => {
        return <PermitSupportingDocs
            visitId={visitId}
            permit={data}
            selectedPermitType={permitType}
            documentsResponse={supportDocsResponse}
            refreshDocumentsResponse={forceUpdate}
            setRiskAssessmentMissing={setRiskAssessmentMissing}
            setMethodStatementMissing={setMethodStatementMissing}
            setRescuePlanMissing={setRescuePlanMissing}
        />
    }, [supportDocsResponse, visitId, data, mustRefresh, setRescuePlanMissing, setMethodStatementMissing, setRescuePlanMissing]);

    const RenderHotWorksIsolationSystems = useCallback(() => <div className='hot-work-isolation-systems'>
        <HotWorkIsolations
            ptw={data as HotWorksPTW}
            title="Isolation Systems"
        />
    </div>, [data]);


    const getFormLayout = useCallback((canWrite: boolean, thisPermitType): FieldGroup[] | undefined => {
        const RenderPermitTypePicker = getRenderPermitTypePicker(thisPermitType);
        const RenderPermitRef = getRenderPermitRef();
        const thisLayout = useGetPTWFormLayout({
            data,
            permitType: thisPermitType,
            RenderHotWorksIsolationSystems,
            RenderPermitSupportingDocsPicker,
            RenderPermitTypePicker,
            RenderPermitRef
        });
        return thisLayout;
    }, [thisRecord, getRenderPermitTypePicker, getRenderPermitRef, RenderPermitSupportingDocsPicker, mustRefresh]);

    const formLayout = useRef<FieldGroup[]>();

    // useEffect(() => {
    //     // this useEffect will orchestrate all changes when the permitType changes.  In order to avoid multiple re-renders pretty much everything except the meta
    //     // (because it is fetched remotely and should be the last thing to change) will be a ref.
    //     // first, get the new endpoint:
    //     switch (permitType) {
    //         case "Hot Works": {
    //             detailEndpoint.current = `${baseEndpoint}hot-work-ptws/`;
    //         };
    //             break;
    //         case "Confined Space": {
    //             detailEndpoint.current = `${baseEndpoint}confined-space-ptws/`;
    //         };
    //             break;
    //         case "Limitation of Access": {
    //             detailEndpoint.current = `${baseEndpoint}limitation-of-access-ptws/`;
    //         };
    //             break;
    //         case "Working At Height": {
    //             detailEndpoint.current = `${baseEndpoint}working-at-height-ptws/`;
    //         };
    //             break;
    //         default: {
    //             detailEndpoint.current = `${baseEndpoint}ptws/`;
    //         }
    //     };
    //     // NB we don't set the id if we don't have a specific ptw_type yet as we want to use the list route for whatever the specific
    //     // ptw we're looking to create is - because we're using concrete inheritance on the backend the id for any specific ptw child record
    //     // (e.g. a 'hot works' record) will be the same as the id of the base 'permit to work' record, but we won't be using it until
    //     // the specific permit to work has been created by this form
    //     // second, get the new meta:
    //     let includeIdForPreFlightInfo;
    //     if (permitType) {
    //         if (!data.ptw_type) {
    //             // we're going to need to create the ptw child type and because permitType is defined, 
    //             // the meta will be for creating a child permit 'type', so visit will be removed as a readonly field
    //             includeIdForPreFlightInfo = undefined;
    //         } else {
    //             // we already have a child ptw
    //             includeIdForPreFlightInfo = thisRecord?.id;
    //         }
    //     } else {
    //         // we must be editing the base ptw
    //         includeIdForPreFlightInfo = thisRecord?.id;
    //     }
    //     CommonAPIServices.getPreFlightInfo(detailEndpoint.current, includeIdForPreFlightInfo).then(response => {
    //         canWrite.current = response.canCreate || response.canUpdate;
    //         formFieldsConfig.current = getFormFieldsConfig(!!canWrite.current, permitType, false);
    //         printableFormFieldsConfig.current = getFormFieldsConfig(!!canWrite.current, permitType, true);
    //         formLayout.current = getFormLayout(!!canWrite.current, permitType);
    //         permissions.current = response.privileges;
    //         // now set the meta...
    //         if (!isEqual(metaData, response.meta)) {
    //             setMetaData(response.meta);
    //         }
    //     }).catch(
    //         (error) => { console.log(error) }
    //     )
    //     getPermitTypeOptions({ setPermitOptionResponses, visitId, permitId: thisRecord?.id, permitTypeIsSelected: !!permitType, existingPermitType: data?.ptw_type })
    // }, [permitType, getFormLayout, getFormFieldsConfig])

    useEffect(() => {
        getPermitTypeOptions({ setPermitOptionResponses, visitId, permitId: thisRecord?.id, existingPermitType: thisRecord?.ptw_type })
    }, [thisRecord, data, visitId])

    useEffect(() => {
        if (permitOptionResponses) {
            const thisPermitType = permitType || 'Base';
            const thisResponse = permitOptionResponses[thisPermitType];
            if (thisResponse) {
                canWrite.current = thisResponse.canCreate || thisResponse.canUpdate || thisResponse.canUpdateChild;
                formFieldsConfig.current = getFormFieldsConfig(!!canWrite.current, permitType, false);
                printableFormFieldsConfig.current = getFormFieldsConfig(!!canWrite.current, permitType, true);
                formLayout.current = getFormLayout(!!canWrite.current, permitType);
                permissions.current = thisResponse.privileges;
                const thisMeta = thisResponse.meta || thisResponse.childMeta;
                detailEndpoint.current = thisResponse.url;//getEndpointForPermitType(visitId, thisResponse.url);
                // now set the meta...
                if (!isEqual(metaData, thisMeta)) {
                    setMetaData(thisMeta);
                }
            }
        }
    }, [permitType, permitOptionResponses, getFormLayout])

    const RenderAfterFormComponent = useCallback(() => {
        return PTWAfterFormComponents({ ptw: thisRecord, permitType, setTopManMissing, setRescuePersonMissing, setEntrantMissing });
    }, [thisRecord, permitType, setTopManMissing, setRescuePersonMissing, setEntrantMissing]);

    // const RenderDescriptionOfIsolations = useCallback(() => {
    //     if (thisRecord.ptw_type === "Hot Works") {
    //         return <HotWorkIsolations
    //             hot_works_permit={data as HotWorksPTW}
    //             title="Isolation Systems"
    //         />
    //     } else {
    //         return <span className="preSaveNote">Isolation systems for hot work permits should be added here once the permit has been saved</span>
    //     }

    // }, [data]);

    // const RenderPTWTrainingCourses = useCallback(() => {
    //     if (thisRecord.ptw_type === "Confined Space") {
    //         return <PTWTrainingCourses
    //             ptw={data as BasePermitToWork}
    //             title="Training Courses"
    //         />
    //     } else {
    //         return <></>
    //     }
    // }, [data]);

    // const RenderRoles = useCallback(() => {
    //     if (thisRecord.ptw_type === "Confined Space") {
    //         return <Roles
    //             ptw={data as BasePermitToWork}
    //             title="Roles and Responsibilities"
    //         />
    //     } else {
    //         return <></>
    //     }
    // }, [data]);


    const processSuccessfulResponse = useCallback((response: any, callback?: any, dataOnly?: boolean | undefined) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        const thisResponse = dataOnly ? response : getDataAndMeta(response);
        const thisData = thisResponse.data;
        const thisMeta = dataOnly ? undefined : thisResponse.meta;
        sharedSpace.data = formValuesRef.current = thisData;
        setThisRecord(thisData);
        if (!dataOnly && !isEqual(metaData, thisMeta)) {
            setMetaData(thisMeta);
        }
        commonUpdateGridRow({
            dataSource,
            key: data.id,
            changes: thisData,
            currentListDataRef: currentListDataRef
        });
        callback && callback();
        forceUpdate();
    }, []);

    const handleSave = useCallback((recordId?: string, callback?: any) => {
        const thisMeta = metaData;
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: thisMeta });
        payload = {
            ...payload,
            permittowork: data.id
        }
        const getEndpoint = () => detailEndpoint.current;
        if (permitType && !thisRecord.ptw_type) {
            CommonAPIServices.create<any>({ getEndpoint, values: payload }).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        } else {
            CommonAPIServices.sensibleUpdate<any>(detailEndpoint.current, payload).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        }
    }, [metaData, processSuccessfulResponse]);

    const generatePermit = useCallback((recordId?: string, callback?: any) => {
        const thisMeta = metaData;
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: thisMeta });
        payload = {
            ...payload,
            'submit_for_signing': true,
            permittowork: data.id
        }
        // NB if the permitType is not set, then the endpoint should be set to point at the 'base' permit record
        if (thisRecord.ptw_type) {
            CommonAPIServices.sensibleUpdate<any>(detailEndpoint.current, payload).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        } else {
            console.log('cannot generate a permit without a permit type');
        }
    }, [thisRecord, formValuesRef, processSuccessfulResponse]);

    sharedSpace.handleSave = handleSave;
    const workPermitFormClass = 'workPermitForm';

    const [theseButtons, setTheseButtons] = useState<GeneralButtonDefinition[]>();

    useEffect(() => {
        const buttons = generateStandardGeneralActionButtonDefinitions({
            handleSave,
            handleSubmit: thisRecord.ptw_type && generatePermit, //thisRecord.ptw_type ? getPDF : undefined,
            formSubmitted: thisRecord.submit_for_signing,
            overrideFormChanged: permitType !== data.ptw_type,
            overrideSubmitLabel: generatePermitLabel,
            overrideSubmittedLabel: 'Should be hidden after permit generated',
            formErrors
        })
        setTheseButtons(buttons);
    }, [handleSave, permitType, thisRecord, generatePermit, mustRefresh, formErrors]);

    if (thisRecord.agreement) {
        return <>
            <WorkPermitDocViewer
                data={data}
                setData={setData}
                docBaseRoute={docBaseRoute}
                preFlightDocInfo={preFlightDocInfo}
                isOpen={!!data.agreement}
                showDownloadButton
                signalRefresh={mustRefresh}
                documentsResponse={supportDocsResponse}
            />
            {canRemovePDF && <RemovePermitPDFButton />}
        </>
    } else if (thisRecord.submit_for_signing && visitId) {
        return <>
            <iframe className="signature-progress-form-wrapper" frameBorder="0" src={`${thisRecord.signature_progress_url}`} />
            {
                !thisRecord?.agreement && thisRecord.ptw_status !== "preparing" && canGeneratePDF && <RetryGeneratePermitPDFButton />
            }
        </>
    } else {
        return <>
            {
                metaData && formFieldsConfig.current && formLayout.current && !thisRecord.submit_for_signing &&
                <>
                    <GeneralEntityForm
                        className={workPermitFormClass}
                        buttons={theseButtons}
                        useDefaultRevertChanges
                        formValuesRef={formValuesRef}
                        refreshSignal={mustRefresh}
                        formLevelSharedSpace={sharedSpace}
                        initialData={thisRecord}
                        canWrite={canWrite.current}
                        formFieldsConfig={formFieldsConfig.current}
                        formLayout={formLayout.current}
                        meta={metaData}
                        dispatchRefreshContext={forceUpdate}
                        gridClass="workPermitsForm fieldGroupsForm"
                        actionsAtTop
                        actionsAtBottom={false}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors} // setFormErrors should never be called outside of GA form
                        generalValidator={generalPTWValidator}
                        AfterFormComponent={RenderAfterFormComponent}
                        highlightSubmissionRequirements
                        requirementsHelpText={requirementsHelpText.current}
                    />
                </>
            }
        </>
    }
}
// AppriseWorkPermitDetailView.whyDidYouRender = true;
export default AppriseWorkPermitDetailView;