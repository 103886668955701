import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

// Own
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo, LookupEntity } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getVisitVisitReportsRoute } from "components/Schedulers/Services/visitService";
import { BasePermitToWork, WorkPermitType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { VisitDocument } from "components/Schedulers/Interfaces/Schedule.interfaces";
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { getDataAndMeta } from "services/API/API.helper";
import { generatePreFlightInfoFromResponse } from "components/ContractInFocus/Services/commonAPI.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import API from "services/API/API";

interface PermitSupportingDocProps {
    visitId: string;
    permit: BasePermitToWork;
    documentsResponse?: any;
    selectedPermitType?: WorkPermitType;
    refreshDocumentsResponse: () => void;
    setRiskAssessmentMissing: React.Dispatch<React.SetStateAction<boolean>>;
    setMethodStatementMissing: React.Dispatch<React.SetStateAction<boolean>>;
    setRescuePlanMissing: React.Dispatch<React.SetStateAction<boolean>>;
}

const PermitSupportingDocs = ({
    visitId,
    permit,
    refreshDocumentsResponse,
    documentsResponse,
    setRiskAssessmentMissing,
    setMethodStatementMissing,
    setRescuePlanMissing,
    selectedPermitType,
}: PermitSupportingDocProps) => {
    // the reason we pass the document in, rather than getting it in here, is that as there may be several permit supporting docs
    // this way we avoid making a query for each one - also, if this component is rendered as part of a form, we avoid 
    // requesting the document info every time the form rerenders
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);
    const visitReportBasePath = getVisitVisitReportsRoute(visitId);
    const [validChoices, setValidChoices] = useState<LookupEntity[]>([]);
    const supportDocTypes = useRef(["risk_assessment", "method_statement", "RAMS", "emergency_rescue_plan"]);
    const [documents, setDocuments] = useState<VisitDocument[]>([]);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>()

    useEffect(() => {
        if (documentsResponse) {
            const processedResponse = getDataAndMeta(documentsResponse);
            const theseDocs = processedResponse?.data.filter((x: VisitDocument) => supportDocTypes.current.includes(x.visit_document_category));
            setDocuments(theseDocs);
            //setDocuments(processedResponse?.data);
            const thisPreFlightVisitReportsInfo = generatePreFlightInfoFromResponse(documentsResponse);
            setPreFlightInfo(thisPreFlightVisitReportsInfo);
        }
    }, [documentsResponse]);

    useEffect(() => {
        let choices = preFlightInfo?.meta?.visit_document_category?.choices;
        const currentDocTypes = documents.map(x => x.visit_document_category)
        const unavailableDocTypes = [...currentDocTypes];
        if (choices) {
            choices = choices.filter((x: LookupEntity) => {
                let v = x.value as string;
                if (currentDocTypes.includes("RAMS")) {
                    if (!unavailableDocTypes.includes("risk_assessment")) {
                        unavailableDocTypes.push("risk_assessment");
                    }
                    if (!unavailableDocTypes.includes("method_statement")) {
                        unavailableDocTypes.push("method_statement");
                    }
                }
                if (currentDocTypes.includes("method_statement") || currentDocTypes.includes("risk_assessment")) {
                    if (!unavailableDocTypes.includes("RAMS")) {
                        unavailableDocTypes.push("RAMS");
                    }
                }
                return supportDocTypes.current.includes(v) && !unavailableDocTypes.includes(v);
            })
            const thisPermitType = permit.ptw_type || selectedPermitType;
            setRescuePlanMissing(thisPermitType === "Confined Space" && !currentDocTypes.includes("emergency_rescue_plan"));
            setRiskAssessmentMissing(!unavailableDocTypes.includes("risk_assessment"));
            setMethodStatementMissing(!unavailableDocTypes.includes("method_statement"));
            setValidChoices(choices);
        }
    }, [documents, preFlightInfo, setRescuePlanMissing, setRiskAssessmentMissing, setMethodStatementMissing, permit.ptw_type, selectedPermitType]);

    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        return {
            'visit_document_category': {
                metaOverride: {
                    choices: validChoices
                }
                //hidden: true,
            },
            'visit': {
                hidden: true,
                defaultValue: visitId
            },
            'permit': {
                hidden: true,
                defaultValue: permit.id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            },
            'document_ref': {
                forceRequired: true
            },
        }
    }, [visitId, permit, selectProfileOrganisationFromState, validChoices]);

    const fieldsConfig = getFieldFormConfig();

    return <>


        {visitReportBasePath && preFlightInfo?.canCreate && !!validChoices.length &&
            <DocumentsUploader
                urlContext={visitReportBasePath}
                fieldConfigs={fieldsConfig}
                preFlightInfo={preFlightInfo}
                className="typed-visit-detail-documents-uploader"
                zIndex={1300}
                reset={refreshDocumentsResponse}
            />
        }
        <div className='permit-support-documents-group-wrapper'>
            <span>Permit SSOW Supporting Documents:&nbsp;&nbsp;</span><br />
            <div className='permit-support-documents-group-flex-wrapper'>
                {
                    documents && documents.map(document => {
                        const choices = preFlightInfo?.meta?.visit_document_category?.choices;
                        const choice = choices?.find((x: LookupEntity) => x.value === document.visit_document_category);
                        const displayName = choice ? choice.display_name : undefined;
                        return displayName ? <div key={document.id} className={`${document.visit_document_category} permit-supporting-document-wrapper`}>
                            <span className='permit-supporting-document-title'>{`${displayName}:`}&nbsp;</span>
                            <a href={`${document.file}`} target='_blank' rel="noopener noreferrer">{document.document_ref ? document.document_ref : document.title}</a>&nbsp;
                            {!permit.submit_for_signing && <span onClick={() => API.delete(`${visitReportBasePath}${document.id}/`).then(() => refreshDocumentsResponse())}><FontAwesomeIcon icon={faTimes} style={{ color: "darkgray", cursor: "pointer" }} /></span>}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </div> : <div key={document.id} ></div>
                    })
                }
            </div>
        </div>
    </>
}

export default PermitSupportingDocs;