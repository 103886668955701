import React, { useCallback, useState, useRef, useReducer } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { newGetPayloadFromRef } from 'services/API/API.helper';
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { Dictionary, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { BasePermitToWork } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface PermitCancellationFormProps {
    permit: BasePermitToWork;
    cancelPermitCallback: (permitId: string, payload: Dictionary<Primitive>) => void;
    metaData: FieldMetaGroup;
    close: () => void;
}

const eventLogFormConfig: FieldsFormConfig = {
    cancellation_reason: {
        multiline: true,
        forceRequired: true,
        rows: 3,
        fieldValidator: ({ formValuesRef, value, meta }) => {
            let thisValue = value as string;
            return {
                'save': !thisValue || thisValue.trim().length < 15 ? 'The reason must be at least 15 characters long' : undefined
            }
        }
    },
};

function PermitCancellationView({
    permit,
    cancelPermitCallback,
    metaData,
    close,
}: PermitCancellationFormProps) {
    const [thisData, setThisData] = useState(permit);
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const formLevelSharedSpace = useRef({});
    const formValuesRef = useRef({});

    const cancelPermit = useCallback((e: any) => {
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: metaData })
        payload = {
            ...payload,
            cancellation_requested: true,
            permittowork: permit.id,

        }
        cancelPermitCallback(permit.id, payload);
    }, [permit, cancelPermitCallback, metaData]);

    const getButtons = useCallback(() => {
        const theseButtons = generateStandardGeneralActionButtonDefinitions({
            handleSave: cancelPermit,
            overrideSaveLabel: 'Confirm Cancellation',
            overrideCancelLabel: 'Exit',
            formErrors: formErrors,
            showDelete: false,
        });
        return theseButtons;
    }, [cancelPermit, formErrors]);

    return <>
        <Dialog
            className={`mat-confirmation-dialog cancel-permit-dialog`}
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{`Cancel Permit`}</DialogTitle>
            <div className="permit-cancel-close-button">
                <IconButton onClick={close} >
                    <CloseIcon />
                </IconButton>
            </div>
            <DialogContent>
                <GeneralEntityForm
                    buttons={getButtons()}
                    formValuesRef={formValuesRef}
                    useDefaultRevertChanges={false}
                    refreshSignal={mustRefresh}
                    formLevelSharedSpace={formLevelSharedSpace.current}
                    initialData={thisData}
                    canWrite={true} // we should only be displaying this form if this has been checked in the parent component
                    formFieldsConfig={eventLogFormConfig}
                    meta={metaData}
                    dispatchRefreshContext={forceUpdate}
                    gridClass="permitCancellationForm"
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                />
            </DialogContent>
        </Dialog>
    </>
}

export default React.memo(PermitCancellationView);