import React, { useState, useEffect, useRef, useCallback } from "react";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Paper } from "@material-ui/core";
import { isEqual } from "lodash";
import { Button } from "@material-ui/core";

import moment from "moment";

import { dateTimeUDF } from "components/Common/Utils/Dates";
import { downloadCSV } from "store/Common/Helpers/commonHelpers";
import API from 'services/API/API.js';
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import Header from "../../Common/Components/GridHeader/GridHeader";
import PrintDataGrid from "../../Common/Components/PrintDataGrid/PrintDataGrid";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { InlineWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { ColumnProps, DataGridMeta } from '../interfaces/dataGridColumn.interface';
import { getColumnProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from "components/ContractInFocus/Models/Grid";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { getSubTitleNumber } from "components/ContractInFocus/ContractReport/Helpers/contractReportToc.helper";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSectionStructure } from "components/ContractInFocus/Models/contractIndex.model";
import { PrintDataGridVisibleColumns } from "components/Common/Components/PrintDataGrid/Interfaces/printDataGrid.interfaces";
import { NewLogsProps } from "components/ContractInFocus/Logs/Logs";
import { tocsSelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import { useSelector } from "react-redux";
import { PrintTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { getRemoteOpParamsAndContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';

interface LogsWrapperProps {
    getEndpoint: (id?: string | number) => string;
    contract?: SiteContract | undefined;
    portfolio?: HydratedPortfolio;
    sectionNumberMap?: Dictionary<number>;
    logsHandle: string;
    logsTitle: string;
    logColumnsDef: PrintDataGridVisibleColumns[];
    WrappedLogsGrid: React.FC<NewLogsProps>;
    remoteOperations?: boolean;
    pageSize?: number;
    filterRow?: boolean;
    csvFileNameBase?: string;
    priorityOnly?: boolean;
}

const LogsWrapper: React.FC<LogsWrapperProps> = ({
    getEndpoint,
    contract,
    portfolio,
    sectionNumberMap,
    logsHandle,
    logsTitle,
    logColumnsDef,
    WrappedLogsGrid,
    remoteOperations,
    pageSize,
    filterRow,
    csvFileNameBase,
    priorityOnly
}: LogsWrapperProps) => {

    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [subTitleIndex, setSubTitleIndex] = useState<string>('');
    const [, setContentReady] = useState(false);

    const tocs = useSelector(tocsSelector);
    const storedData = useRef<any>();
    const storedLoadOptions = useRef<any>();

    const getColumnPropsExt = (field: string, overrides?: any): ColumnProps => {
        let col = metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
        // if (field == "contract") {
        //     console.log('metadata.activeMeta:', metadata.activeMeta);
        //     console.log('cols:', cols);
        // }
        const captializeColumns = ['system', 'plant_name', 'category'];
        if (captializeColumns.includes(field.toLowerCase())) {
            col.cssClass = col.cssClass ? `${col.cssClass} capitalize` : 'capitalize';
        }
        const currOverrides = overrides ? overrides[field] || {} : {};
        return { ...col, ...currOverrides }
    }

    const theseLogColumnsDef = useCallback(() => {
        let localLogColumnsDef = logColumnsDef;
        if (portfolio) {
            localLogColumnsDef = [
                { name: "contract", width: "6%", caption: 'Contract' },
                ...logColumnsDef
            ]
        }
        return localLogColumnsDef
    }, [portfolio, logColumnsDef])

    const handleOnRowPrepared = (e: any) => {
        if (e.rowType == 'data') {
            e.rowElement.mousemove(function () {
                e.rowElement.prop('title', `Created At: ${moment(e.data.created_at).format("DD-MM-YYYY HH:mm")}`)
            })
        }
    }

    useEffect(() => {

        const thisKey = portfolio?.id || contract?.contract_ref;
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                // NB the inclusion of the contract column in portfolio mode made this dxDatagrid get stuck in a loading loop
                // when a filter was applied to the header (regardless of whether we were using remoteOperations and auto filter application or onclick)
                // it only happened in a portfolio context where the contract represented a choice field under the hood
                // (it didn't happen in contract mode even if the contract column was included - there the column is just a simple field) 
                //  and it only made the grid keep triggering it's own load function - it wasn't refreshing the whole component and it was not
                // re-calling this useEffect.
                // It seemed to be a bug, and it's been dealt with by comparing the load options to a cached version of the load options before
                // firing a reload.
                if (!storedData.current || !isEqual(storedLoadOptions.current, loadOptions)) {
                    storedLoadOptions.current = loadOptions;
                    let params = getRemoteOpParamsAndContractScopeParams({ loadOptions })
                    if (priorityOnly) {
                        params = {
                            ...params,
                            priority: 1
                        }
                    }
                    const thisData = CommonAPIService.getAll<any>(
                        getEndpoint,
                        setMetadata,
                        thisKey,
                        params
                    )
                    storedData.current = thisData;
                    return thisData;
                } else {
                    return storedData.current;
                }
            },
            //loadOptions => CommonAPIService.getAll<any>(getEndpoint, setMetadata, contract.contract_ref),
            insert: values => {
                storedData.current = undefined;
                return CommonAPIService.create<any>({ getEndpoint, contract, portfolio, values })
            },
            // @ts-ignore
            remove: key => {
                storedData.current = undefined;
                return CommonAPIService.del<any>(getEndpoint, thisKey, key)
            },
            update: (id, values) => {
                storedData.current = undefined;
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );

    }, [contract, getEndpoint, portfolio]);

    // useEffect(() => {
    //     if (portfolio) {
    //         const custom = new CustomStore({
    //             key: "id",
    //             load: loadOptions => CommonAPIService.getAll<any>(getEndpoint, setMetadata, portfolio.id),
    //             insert: values => CommonAPIService.create<any>({ getEndpoint, portfolio, values }),
    //             // @ts-ignore
    //             remove: key => CommonAPIService.del<any>(getEndpoint, portfolio.id, key),
    //             update: (id, values) => CommonAPIService.update<any>(getEndpoint, portfolio.id, id, values)
    //         });


    //         setDataSource(
    //             new DataSource({
    //                 store: custom
    //             })
    //         );
    //     }
    // }, [portfolio, getEndpoint]);

    // useEffect(() => {
    //     if (!portfolio && !contract) {
    //         const custom = new CustomStore({
    //             key: "id",
    //             load: loadOptions => CommonAPIService.getAll<any>(getEndpoint, setMetadata),
    //             insert: values => CommonAPIService.create<any>({ getEndpoint, portfolio, values }),
    //             // @ts-ignore
    //             remove: key => CommonAPIService.del<any>(getEndpoint, key),
    //             update: (id, values) => CommonAPIService.update<any>(getEndpoint, undefined, id, values)
    //         });


    //         setDataSource(
    //             new DataSource({
    //                 store: custom
    //             })
    //         );
    //     }
    // }, [portfolio, contract, getEndpoint]);

    useEffect(() => {
        const thisSubTitleIndex = getSubTitleNumber(
            logsTitle,
            tocs.flatTocsLookup
        )
        thisSubTitleIndex && setSubTitleIndex(thisSubTitleIndex);
    }, [tocs, logsTitle])

    // WIP - could be used to add a better tooltip (hint.css?)
    // const addRowIds = useCallback(grid => {
    //     // to fire this we could pass it down and assign it as a ref to the grid itself, that would actually fire it once assigned
    //     // however, we might as well call it with the grid in the onContentReady hook provided by dx
    //     // we need to add the created_at of the actual event record to the DOM <tr> record for the row 
    //     // to do this we iterate rows, get the row data and lookup the created_at and the row index
    //     // we then call grid.getRowElement(rowIndex) to get the relevant DOM element.  With that element
    //     // we can then add the data-hint attribute to be the created_at date on that element.
    //     if (grid !== null) {
    //         console.log('rows are: ', grid.instance.getVisibleRows());
    //         setTimeout(() => {
    //             console.log('rows are: ', grid.instance.getVisibleRows());
    //         }, 1000)
    //         const g = $(grid)//.querySelectorAll('tr');
    //         console.log('g is: ', g);
    //     }
    // }, []);

    // const onContentReady = useCallback((e: any) => {
    //     // we need to add the created_at of the actual event record to the DOM <tr> record for the row 
    //     // to do this we iterate rows, get the row data and lookup the created_at and the row index
    //     // we then call grid.getRowElement(rowIndex) to get the relevant DOM element.  With that element
    //     // we can then add the data-hint attribute to be the created_at date on that element.
    //     const grid = e.element;
    //     setContentReady(true);
    // }, []);

    const handleEditingStart = () => {
        if (metadata && metadata.activeMeta !== metadata.PUTMeta) {
            setMetadata({ ...metadata, activeMeta: metadata.PUTMeta });
        }
    }

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const getCsvFileName = () => `${csvFileNameBase} as of ${moment().format(dateTimeUDF)}`; // we use a callback to ensure the time is up to date

    const csvEndpoint = getEndpoint(portfolio?.id || contract?.contract_ref) + 'csv/'

    const getCSV = () => {
        API.get(csvEndpoint, { headers: { "Accept": "text/csv" } }).then((response) => {
            downloadCSV(response.data, getCsvFileName());
        });
    }

    const ExtraButtons = () => <Button className="get-csv h-and-s-incidents" onClick={() => getCSV()}>
        DOWNLOAD AS CSV
    </Button>

    return (
        <InlineWrapper
            data-test-id={`test-${logsHandle}-id`}
        >
            <Paper elevation={3}>
                <div className="no-break-inside" style={{ pageBreakInside: "avoid" }}>
                    {sectionNumberMap && <PrintTitle sectionNumberMap={sectionNumberMap} reportSection={reportSectionStructure.Events} />}
                    <Header
                        title={`${subTitleIndex} ${logsTitle}`}
                        subTitle={getSubTitle(metadata)}
                        className="no-screen"
                        level={1}
                    />
                    <Header
                        title={logsTitle}
                        subTitle={getSubTitle(metadata)}
                        className="no-print"
                        ExtraButtons={csvFileNameBase ? ExtraButtons : undefined}
                    />

                    <div>

                        {dataSource && <WrappedLogsGrid
                            dataSource={dataSource}
                            metadata={metadata}
                            setContentReady={setContentReady}
                            handleEditingStart={handleEditingStart}
                            handleRowUpdated={handleRowUpdated}
                            handleOnRowPrepared={handleOnRowPrepared}
                            getColumnPropsExt={getColumnPropsExt}
                            contract={contract}
                            portfolio={portfolio}
                            remoteOperations={remoteOperations}
                            pageSize={pageSize}
                            filterRow={filterRow}
                        />}
                    </div>
                    <PrintDataGrid
                        meta={metadata.activeMeta}
                        visibleColumns={theseLogColumnsDef()}
                        records={dataSource?.items()}
                    />
                </div>

            </Paper>
        </InlineWrapper>
    );
};

export default LogsWrapper;
