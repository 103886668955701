import React, { useState, useEffect, useRef, useCallback, useReducer, memo, useMemo } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { Paper } from "@material-ui/core";

// Own
import { AutoInput, RequirementsHelpText } from 'components/Common/Components/AutoInput/AutoInput';
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FieldMetaGroup, Dictionary, Primitive, FieldGroup } from "components/Common/Interfaces/Entity.interface";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';
import { defaultFormFieldValidator } from "store/Common/Helpers/commonHelpers";
import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";
import { logObjDifferences } from "store/reducers/reducer.helper";

// Styles
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import { isEqual } from 'lodash';

export interface FormValues {
    [field: string]: Primitive; //represents field: actual value
}


interface ValidatorProps {
    formValuesRef: React.MutableRefObject<FormValues>;
    previousValuesRef: React.MutableRefObject<FormValues | undefined>;
    previousGeneralValidationsRef?: React.MutableRefObject<FormErrorsType | undefined>;
    formErrors?: FormErrorsType;
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>;
    fieldConfigs: FieldsFormConfig;
    metaForForm: FieldMetaGroup;
    highlightSubmissionRequirements?: boolean;
    requirementsHelpText?: RequirementsHelpText
}

export type GeneralFormValidator = (props: ValidatorProps) => FormErrorsType;
interface RunAllValidatorProps extends ValidatorProps {
    generalValidator?: GeneralFormValidator;
}

const runAllValidators = (
    {
        formValuesRef,
        previousValuesRef,
        previousGeneralValidationsRef,
        formErrors,
        setFormErrors,
        fieldConfigs,
        metaForForm,
        generalValidator,
        highlightSubmissionRequirements,
        requirementsHelpText
    }: RunAllValidatorProps) => {
    // NB when this function is used it should be the ONLY function to alter formErrors (i.e. to call setFormErrors)
    // because otherwise an infinite regression is possible when it looks to compare the existing errors with 
    // the ones it would set now (i.e. if they're changed afterwards by some other function, every time it runs).  
    // However, as an extra guard rail, and also so that formErrors from the B/E that might be set 
    // will persist until the field values are changed, this function will only run when at least 
    // one value in the form has changed since it last ran (we don't check for the exact same field value before running a particular validation
    // as it is conceivable that this function would need to run on a different field to the one where the value has changed)
    let generalErrors: FormErrorsType = {};
    let generalErrorsChanged = false;
    const valuesChanged = !isEqual(previousValuesRef.current, formValuesRef.current);
    if (previousGeneralValidationsRef && generalValidator) {
        const theseGeneralErrors = generalValidator({
            formValuesRef,
            previousValuesRef,
            formErrors,
            setFormErrors,
            fieldConfigs,
            metaForForm
        });
        if (!isEqual(previousGeneralValidationsRef.current, theseGeneralErrors)) {
            generalErrors = theseGeneralErrors;
            generalErrorsChanged = true;
            previousGeneralValidationsRef.current = generalErrors;
        } else {
            generalErrors = previousGeneralValidationsRef.current ? previousGeneralValidationsRef.current : {};
        }
    }

    if (setFormErrors && formErrors && (valuesChanged || generalErrorsChanged)) {

        let newFieldValidationErrors: FormErrorsType = {};
        Object.keys(fieldConfigs).map(
            (k) => {
                // NOTE this function must do EVERYTHING it is going to do in terms of validation adjustments, and THEN compare the results to the 
                // existing - that way it will be possible to check if anything material has changed and prevent an infinite loop.
                // NB there will nearly always be a concept of when an object can be saved and frequently when some further action can be taken - and this is related to
                // B/E restrictions, communicated via the meta.  Therefore calculating when an object can be saved or subject to a further action ('submitted')
                // are common 'special' cases - and we handle them here for convenience. 
                // First predict errors based on meta info
                const config = fieldConfigs[k];
                //const predictableFieldValidationMessages = predictCommonErrorsUsingMeta({ formErrors, formValuesRef, field: k, fieldConfigs, metaForForm });
                const defaultValidation = defaultFormFieldValidator({ field: k, config, formValuesRef, value: formValuesRef.current[k], meta: metaForForm[k], highlightSubmissionRequirements, requirementsHelpText })
                // Second, run specific calcs based on more particular logic stored in fieldValidators in the formConfig

                const validator = config.fieldValidator;
                const validation = validator ? validator({ field: k, config, formValuesRef, value: formValuesRef.current[k], meta: metaForForm[k], highlightSubmissionRequirements, requirementsHelpText }) : false;
                // Combine the two with the 'specific' calcs in the formValidator overriding (as they have access to the meta too and are form specific)
                const newFieldValidation = { ...defaultValidation, ...validation, }; // done per field so it will happily combine the result of default and custom validators (e.g. could take 'input' from one and 'save' and 'submit' from the other)
                newFieldValidationErrors[k] = newFieldValidation;
            }
        );

        const newValidationErrors: FormErrorsType = { ...newFieldValidationErrors, ...generalErrors };

        if (!isEqual(formErrors, newValidationErrors)) {
            // const dataDiff = logObjDifferences(formErrors, newValidationErrors);
            // if (dataDiff) {
            //     console.log('dataDiff: ', dataDiff);
            //     dataDiff.forEach(x => {
            //         const oldError = formErrors[x];
            //         const newError = newValidationErrors[x];
            //         console.log('field: ', x, ' oldError: ', oldError, 'newError: ', newError);
            //     })
            // }
            setFormErrors(newValidationErrors);
        }
    }
};

interface RenderComponentInterface {
    Component: React.FC<any>;
    index: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    groupKey: string;
    skipInViewWrapper?: Boolean;
    className?: string;
}

const RenderComponentInForm = React.memo(({ Component, index, groupKey, skipInViewWrapper, className, inViewOptions, inViewProgressTracker }: RenderComponentInterface) => {
    if (inViewOptions && !skipInViewWrapper) {
        return <InViewWrapper
            key={groupKey}
            WrappedComponent={() => <div className={className || ''}><Component /></div>} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
            inViewOptions={inViewOptions.current}
            inViewProgressTracker={inViewProgressTracker}
            i={index}
            override={undefined} // if false we want to pass this as undefined
            placeHolderMinHeight="25vh"
        />
    }
    return <div className={className}>
        <
            Component
            key={groupKey}
        />
    </div>
});

const getFieldErrorFromFormErrors = (dataField: string | React.FC<any>, formErrors?: FormErrorsType) => {
    let thisError: Dictionary<string | undefined> = {};
    if (typeof (dataField) === "string") {
        thisError = formErrors ? formErrors[dataField] : {};
    }
    const fieldError = thisError ? Object.prototype.hasOwnProperty.call(thisError, 'inputValidations') ? thisError['inputValidations'] : thisError['save'] : undefined;
    return fieldError;
}

const getGroupClassName = (group: FieldGroup) => `${group.className}${group.children?.length ? ' parent' : ''} ${group.component ? '' : 'field-group-wrapper'}`;

interface ActionFormProps {
    formValues: React.MutableRefObject<FormValues>;
    fieldConfigs: FieldsFormConfig;
    formLayout?: FieldGroup[];
    generalFieldZindex?: number;
    wipe?: boolean;
    callWithOnChange?: (newFormValues: FormValues) => void;
    caption?: string;
    gridClass?: string;
    metaForForm: FieldMetaGroup;
    refreshSignal?: any;
    showReadOnly?: boolean;
    paperElevation?: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    formErrors?: FormErrorsType;
    setFormErrors?: React.Dispatch<FormErrorsType>;
    setGAFormChanged?: React.Dispatch<boolean>; //NOTE that GEF has it's own 'formChanged' type function, 
    // setGAFormChange is here for other components using this component directly - TODO - analyse what's special about the GEF formChanged and if sensible, move 
    // to this GAF component to unify
    addColonToLabel?: boolean;
    generalValidator?: GeneralFormValidator;
    highlightSubmissionRequirements?: boolean;
    requirementsHelpText?: RequirementsHelpText
}

const ActionForm = (
    {
        formValues,
        fieldConfigs,
        generalFieldZindex,
        wipe,
        callWithOnChange,
        caption,
        gridClass,
        metaForForm,
        refreshSignal,
        showReadOnly,
        formLayout,
        paperElevation,
        inViewOptions,
        inViewProgressTracker,
        initiallySelectedDataField,
        formErrors,
        setFormErrors,
        setGAFormChanged,
        addColonToLabel,
        generalValidator,
        highlightSubmissionRequirements,
        requirementsHelpText
    }: ActionFormProps) => {

    const previousFormValues = useRef<FormValues>();
    const previousGeneralValidationsRef = useRef<FormErrorsType>({});
    const initialFormValues = useRef<FormValues>({ ...formValues.current });
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const currentFocus = useRef<string>();
    const [postComponentSelected, setPostComponentSelected] = useState<string | undefined>(initiallySelectedDataField?.current);

    useEffect(() => {
        refreshSignal && forceUpdate();
    }, [refreshSignal])

    // useEffect(() => {
    //     console.log('ActionForm rendered')
    // }, [])

    const scrollToRef: any = useRef();

    const runSideEffects = useCallback((newFormValues: FormValues, fieldConfigs: FieldsFormConfig, onChangeFormValues: (newValues: FormValues) => void) => {
        let sideEffects = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);
        for (let index in sideEffectsToRun) {
            const field = sideEffectsToRun[index];
            //@ts-ignore
            const sideEffectChangedSomething = fieldConfigs[field].sideEffect(newFormValues, fieldConfigs, onChangeFormValues, previousFormValues.current);
            if (sideEffectChangedSomething) {
                sideEffects.push(field)
            }
        }
        runAllValidators({
            // is a side effect, so it runs here (before previousFormValues are updated but after other sideEffects, as those may affect validation, including missing values)
            formValuesRef: formValues,
            formErrors,
            setFormErrors,
            fieldConfigs,
            metaForForm,
            previousValuesRef: previousFormValues,
            generalValidator,
            previousGeneralValidationsRef,
            highlightSubmissionRequirements,
            requirementsHelpText
        });
        previousFormValues.current = newFormValues;
        if (sideEffects.length > 0) {
            forceUpdate();
        }
    }, [metaForForm, formValues, formErrors, setFormErrors, generalValidator, highlightSubmissionRequirements, requirementsHelpText]);

    useEffect(() => {
        if (wipe) {
            formValues.current = {};
            forceUpdate();
        }
    }, [wipe, formValues])

    useEffect(() => {
        initiallySelectedDataField && scrollToRef.current && scrollToRef.current.scrollIntoView && scrollToRef.current.scrollIntoView()
    }, [scrollToRef, initiallySelectedDataField])

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        const updatedFormValues = { ...formValues.current, ...newValues }
        formValues.current = updatedFormValues;
        runSideEffects(updatedFormValues, fieldConfigs, onChangeFormValues);
        if (setGAFormChanged) {
            setGAFormChanged(!isEqual(initialFormValues.current, formValues.current));
        }
        if (callWithOnChange) {
            callWithOnChange(updatedFormValues);
        }
    }, [
        fieldConfigs,
        formValues,
        setGAFormChanged,
        runSideEffects,
        callWithOnChange,
    ]
    )

    const hasMeta = useCallback(() => metaForForm && !!Object.keys(metaForForm).length, [metaForForm]);
    const RenderField = useCallback(({ dataField, fieldConfigs, fieldError }: { dataField: string | React.FC<any>, fieldConfigs: FieldsFormConfig, fieldError: string | undefined }) => {
        if (typeof dataField === "string") {
            const fieldConfig = fieldConfigs[dataField]
            return (fieldConfig ? <AutoInput
                wrapperRef={initiallySelectedDataField?.current === dataField ? scrollToRef : undefined}
                zIndex={generalFieldZindex || 3001}
                key={dataField}
                dataField={dataField}
                fieldConfig={fieldConfig}
                fieldMeta={metaForForm[dataField]}
                formValuesRef={formValues}
                onChangeFormValues={onChangeFormValues}
                currentFocus={currentFocus}
                refreshSignal={mustRefresh}
                dispatchRefreshContext={forceUpdate}
                showReadOnly={showReadOnly}
                setPostComponentSelected={setPostComponentSelected}
                extraClassNames={postComponentSelected === dataField ? 'postComponentSelected' : 'postComponentNotSelected'}
                fieldError={fieldError} // currently indicates F/E errors but could be integrated with B/E form Error feedback
                addColonToLabel={addColonToLabel}
                highlightSubmissionRequirements={highlightSubmissionRequirements}
                requirementsHelpText={requirementsHelpText}
            /> : <></>)
        } else {
            const DataField = dataField
            return <DataField />
        }

    }, [
        generalFieldZindex,
        metaForForm,
        formValues,
        onChangeFormValues,
        currentFocus,
        mustRefresh,
        showReadOnly,
        postComponentSelected,
        initiallySelectedDataField,
        addColonToLabel,
        highlightSubmissionRequirements,
        requirementsHelpText
    ]);

    // const changedCount = useRef(0);

    // useEffect(() => {
    //     console.log('metaForForm: ', metaForForm);
    // }, [metaForForm])

    // useEffect(() => {
    //     changedCount.current += 1;
    //     console.log('SOMETHING CHANGED!!!: ', changedCount.current);
    // }, [
    //     // RenderField dependencies
    //     // generalFieldZindex,
    //     // metaForForm,
    //     // formValues,
    //     // setFormErrors,
    //     // onChangeFormValues,
    //     // currentFocus,
    //     // mustRefresh,
    //     // showReadOnly,
    //     // postComponentSelected,
    //     // initiallySelectedDataField,
    //     // addColonToLabel

    //     // onChangeFormValues dependencies
    //     // fieldConfigs,
    //     // formValues,
    //     // setGAFormChanged,
    //     // runSideEffects,
    //     // callWithOnChange,

    //     // runSideEffects dependencies
    //     //metaForForm, //2
    //     //formValues, 
    //     //formErrors, //3 - this causes reevaluation on each keystroke
    //     //setFormErrors, 
    //     // generalValidator, //1
    // ]);

    const RenderComponentOrFieldGroup = useCallback(({ group, i }: { group: FieldGroup, i: number }) => {
        if (group.component) {
            // NB DO NOT PASS IN COMPONENTS WHICH WILL SEND REQUESTS TO THE BACKEND ON EACH RENDER, OR ONES THAT ARE COMPLEX
            // AS THEY WILL BE REEVALUATED WHENEVER THE FORM IS EVALUATED - WHICH WILL BE ON MOST VALUE CHANGES.
            return <RenderComponentInForm
                Component={group.component}
                skipInViewWrapper={group.skipInViewWrapper}
                className={getGroupClassName(group)}
                index={i}
                groupKey={group.group_id || group.group_title}
                inViewOptions={inViewOptions}
                inViewProgressTracker={inViewProgressTracker}
            />
        }
        return <RenderFieldGroup
            group={group}
            key={group.group_id || group.group_title}
            fieldConfigs={fieldConfigs}
            formErrors={formErrors}
        />
    }, [inViewOptions, inViewProgressTracker, fieldConfigs, formErrors]);

    const RenderFormLayout = ({ formLayout, paperElevation }: { formLayout: FieldGroup[], paperElevation?: number }) => {
        return <>
            {paperElevation ?
                <>{
                    formLayout.map((group, i) => {
                        return <Paper elevation={paperElevation} key={group.group_id || group.group_title}>
                            <RenderComponentOrFieldGroup group={group} i={i} />
                        </Paper>
                    })
                } </>
                :
                <>{
                    formLayout.map((group, i) => {
                        return <RenderComponentOrFieldGroup group={group} i={i} key={group.group_id || group.group_title} />
                    })
                }</>
            }
        </>
    }

    const RenderFieldGroup = ({ group, fieldConfigs, formErrors }: { group: FieldGroup, fieldConfigs: FieldsFormConfig, formErrors: FormErrorsType | undefined }) => {
        return <div className={getGroupClassName(group)}>
            <h3 className='field-group-title'>{group.group_title}</h3>
            {group.group_subtitle && <h4 className='field-group-subtitle'>{group.group_subtitle}</h4>}
            {
                group.fields.map((dataField, i) => {
                    const fieldError = getFieldErrorFromFormErrors(dataField, formErrors);
                    const ThisField = React.memo(() => <RenderField
                        key={i}
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                        fieldError={fieldError}
                    />)
                    if (inViewOptions?.current && !initiallySelectedDataField && !group.skipInViewWrapper) {
                        return <InViewWrapper
                            key={i}
                            WrappedComponent={() => <ThisField />} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
                            inViewOptions={inViewOptions.current}
                            inViewProgressTracker={inViewProgressTracker}
                            i={i}
                            override={undefined} // if false we want to pass this as undefined
                            placeHolderMinHeight="25vh"
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                        />
                    } else {
                        return <ThisField key={i} />
                    }

                })
            }

            {
                group.children && <RenderFormLayout
                    formLayout={group.children}
                />

            }

        </div>
    }

    const RenderSimpleForm = ({ paperElevation }: { paperElevation?: number | undefined }) => {
        return (paperElevation ? <Paper elevation={paperElevation}>
            <>
                {
                    Object.keys(fieldConfigs).map((dataField) => {
                        const fieldError = getFieldErrorFromFormErrors(dataField, formErrors);
                        const ThisField = React.memo(() => <RenderField
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                            fieldError={fieldError}
                        />)
                        return <ThisField key={dataField} />
                    })
                }
            </>
        </Paper> : <>
            {
                Object.keys(fieldConfigs).map((dataField) => {
                    const fieldError = getFieldErrorFromFormErrors(dataField, formErrors);
                    const ThisField = React.memo(() => <RenderField
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                        fieldError={fieldError}
                    />)
                    return <ThisField key={dataField} />
                })
            }
        </>)
    }

    useEffect(() => {
        // NB there are many side effects we might want to run immediately - so it's important we run onChangeFormValues once on load
        onChangeFormValues(formValues.current);
    }, [formValues, onChangeFormValues])

    return <div className="generalActionFormWrapper">
        {caption && <span className="actionFormCaption">{caption}</span>}
        {
            hasMeta() &&
            <div className={`generalActionFormGrid ${gridClass ? gridClass : ''}`}>
                {
                    mustRefresh && <>
                        {formLayout ?
                            <RenderFormLayout
                                formLayout={formLayout}
                                paperElevation={paperElevation}
                            /> : <RenderSimpleForm paperElevation={paperElevation} />
                        }
                    </>
                }
            </div>
        }

    </div>
}

// ActionForm.whyDidYouRender = true;

export default React.memo(ActionForm);