import React from "react";
import DataGrid, { Column, Editing, Paging, Lookup, RequiredRule } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { subContractEventLogsColumns, subContractEventLogsColumnWidths, portfolioSubContractEventLogsColumnWidths } from "../Models/logsColumns.model";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";

const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/subcontractor-event-logs/?format=json`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/subcontractor-event-logs/?format=json`;

const SubcontractorEventLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        portfolio,
        contract,
        styles
    }) => {
    const columnWidths = portfolio ? portfolioSubContractEventLogsColumnWidths : subContractEventLogsColumnWidths;
    return (

        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={getEventEditorPreparing(metadata)} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    style={styles}
                >
                    <Paging enabled={false} />
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {portfolio && <Column
                        {...getColumnPropsExt('contract')}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>}
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('system')}
                        width={columnWidths['system']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('plant_name')}
                        width={columnWidths['plant_name']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column {...getColumnPropsExt('resolved_on')}
                        width={columnWidths['resolved_on']}
                    />
                    <Column {...getColumnPropsExt('resolution')}
                        width={columnWidths['resolution']}
                    />

                </DataGrid>
            )}
        </div>

    );
};

interface GetWrappedSubcontractorEventLogProps {
    contract: any;
    portfolio: any;
    sectionNumberMap?: Dictionary<number>;
    priorityOnly?: boolean
}
const getWrappedSubcontractorEventLogs = ({ contract, portfolio, sectionNumberMap, priorityOnly }: GetWrappedSubcontractorEventLogProps) => {
    return () => {
        let getEndpoint: any;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={SubcontractorEventLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={subContractEventLogsColumns}
            logsHandle='SubcontractorEvents'
            logsTitle={reportSubtitles.SubcontractorEvents}
            sectionNumberMap={sectionNumberMap}
            priorityOnly={priorityOnly}
        />
    }
}

export default getWrappedSubcontractorEventLogs;
