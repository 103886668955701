import React, { useState, useEffect, useCallback } from "react";
import DataGrid, { Column, Editing, RequiredRule } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

// Own
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from 'helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { BasePermitToWork, PTWRescueRole } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";

interface RolesProps {
    ptw: BasePermitToWork;
    title: string;
    setTopManMissing: React.Dispatch<React.SetStateAction<boolean>>,
    setEntrantMissing: React.Dispatch<React.SetStateAction<boolean>>,
    setRescuePersonMissing: React.Dispatch<React.SetStateAction<boolean>>,
}

const Roles: React.FC<RolesProps> = ({
    ptw,
    title,
    setTopManMissing,
    setEntrantMissing,
    setRescuePersonMissing,
}) => {
    const getBaseEndpoint = useCallback(() => `ptws/${ptw.id}/rescue-roles/?format=json`, [ptw]);
    const getEndpoint = getBaseEndpoint;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [contentReady, setContentReady] = useState(false);
    const thisKey = ptw.id;

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                let params: any;
                const thisData = CommonAPIService.getAll<PTWRescueRole>(
                    getEndpoint,
                    setMetadata,
                    thisKey,
                    params
                ).then(response => {
                    const topManMissing = !response?.data.find(x => x.role === "top_man");
                    const entrantMissing = !response?.data.find(x => x.role === "entrant");
                    const rescuePersonMissing = !response?.data.find(x => x.role === "rescue_person");
                    setTopManMissing(topManMissing);
                    setEntrantMissing(entrantMissing);
                    setRescuePersonMissing(rescuePersonMissing);
                    return response;
                })
                return thisData;
            },
            insert: values => {
                const theseValues: PTWRescueRole = { ptw: thisKey, ...values }
                return CommonAPIService.create<any>({ getEndpoint, values: theseValues })
            },
            // @ts-ignore
            remove: key => {
                return CommonAPIService.del<any>(getEndpoint, thisKey, key)
            },
            update: (id, values) => {
                return CommonAPIService.update<any>(getEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [getEndpoint, thisKey, setEntrantMissing, setTopManMissing, setRescuePersonMissing]);

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    return (
        <InlineWrapper className="rescue-roles">
            <GridHeader
                title={title}
                className={`${PrintChartAndTableLabels ? '' : 'no-print'}`}
                subTitle={getSubTitle(metadata)}
            />
            {dataSource && (
                <DataGrid
                    className='no-print'
                    dataSource={dataSource}
                    //onRowInserting={handleRowInserting}
                    //onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                    onContentReady={() => setContentReady(true)}
                    showColumnHeaders
                >
                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {renderColIfMetaDataDefined('role', true)}
                    {renderColIfMetaDataDefined('name', true)}
                </DataGrid>
            )}
        </InlineWrapper>
    );
};

export default Roles;